/* Center the login form on the page */
.login-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f7f9fc;
    font-family: 'Arial', sans-serif;
  }
  
  h2 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 1.5rem;
  }
  
  form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 400px;
    background: white;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  input {
    font-size: 1rem;
    padding: 0.75rem;
    margin-bottom: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
    transition: all 0.3s ease;
  }
  
  input:focus {
    border-color: #007bff;
  }
  
  button {
    padding: 0.75rem;
    font-size: 1rem;
    background-color: #007bff;
    color: white;
    border: none;
  border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  p {
    color: #e74c3c;
    margin-top: 1rem;
    font-size: 0.9rem;
    text-align: center;
  }
  
  /* For mobile responsiveness */
  @media (max-width: 600px) {
    form {
      padding: 1.5rem;
    }
  
    input {
      font-size: 0.9rem;
    }
  
    button {
      font-size: 0.9rem;
    }
  }
  